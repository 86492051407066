.overmij{
    background-color: var(--white);
    min-height: 50rem;
    padding-bottom: 5rem;
}

.overmij__contentWrapper{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding-top: 5rem;
}

.overmij__figure{
    width: 90%;
    height: auto;
    border: 0.3rem solid transparent;
    border-image: linear-gradient( #000046, #1CB5E0);
    border-image-slice: 1;
}

.overmij__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overmij__content{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
}

.overmij__textSection{
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.overmij__kop{
    color: var(--black);
}

.overmij__kop::after{
    content:"";
    width:10rem;
    height:0.7rem;
    display:block;
    position:absolute;
    background-image: linear-gradient(to right, #000046, #1CB5E0);
}

.overmij__tekst{
    color: var(--black);
}

.email{
    font-size: 150%;
    word-break: break-all;
}

.email:active{
    color: blue;
}

@media screen and (min-width: 600px){
    .overmij__figure{
        width: 65%;
    }
}

@media screen and (min-width: 900px){
    .overmij__contentWrapper{ 
        flex-direction: row-reverse;
        align-items: flex-start;
        height: 60rem;
    }

    .overmij__figure{
        height: 100%;
    }
}

@media screen and (min-width: 1400px){
    .overmij__contentWrapper{
        width: 75%;
        height: 75rem;
        justify-content: space-between;
    }

    .overmij__content{
        width: 50%;
    }

    .overmij__figure{
        width: auto;
    }


}
