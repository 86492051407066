.contact{
    background-color: var(--white);
    min-height: 50rem;
    padding-bottom: 5rem;
    color: var(--black);
}

.contact__contentWrapper{
    padding-top: 2rem;
    width: 90%;
    margin: 0 auto;
}

.contact__content{
    width: 100%;
    margin-bottom: 2rem;
}

.contact__kop{
    margin-bottom: 0.3rem;
}

.contact__form{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
}

.contact__label{
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}

.contact__input{
    width: 100%;
    height: auto;
    padding: 2rem 2rem 2rem 1rem;
    border-style: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    background-color: #DDD;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
}

.textarea{
    height: 40rem;
}

.contact__buttonContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
}

.contact__submit{
    padding: 1rem 2.5rem;
    border-style: none;
    color: var(--white);
    font-family: 'Roboto', sans-serif;
    transition: 0.3s;
    font-size: 1.8rem;
}

.contact__submit:hover{
    cursor: pointer;
    background-color: var(--hover);
}

.primary{
    background-color: var(--blue);
}

@media screen and (min-width: 600px){
    .contact__form{
        grid-template-columns: repeat(2, minmax(0,1fr));
        grid-template-areas: 
                "naam email"
                "telefoon bedrijf"
                "bericht bericht"
                "button button";
        grid-column-gap: 2rem;
    }

    .contact__naam{
        grid-area: naam;
    }

    .contact__telefoon{
        grid-area: telefoon;
    }

    .contact__email{
        grid-area: email;
    }

    .contact__bedrijf{
        grid-area: bedrijf;
    }

    .contact__bericht{
        grid-area: bericht;
    }

    .contact__buttonContainer{
        grid-area: button;
    }

}

@media screen and (min-width: 900px){
    .contact__form{
        grid-column-gap: 4rem;
    }
}

@media screen and (min-width: 1400px){
    .contact__contentWrapper{
        width: 75%;
    }

    .contact__form{
        grid-column-gap: 15rem;
    }
}