:root{
    --blue: #000079;
    --hover: #1484a4;
}

.header{
    height: 100vh;
    background-image: url('./header__mobile.png') ;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
}

.header__overlay{
    width: 100%;
    height: 100vh;
    background-color: var(--black);
    opacity: 0.5;
    position: absolute;
}

.header__content{
    width: 90%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
    gap: 1rem;
    animation-name: fade-in;
    animation-duration: 2s;
}

.header__titelWrapper{
    margin-top: 4rem;
}

.header__titel{
    background-image: linear-gradient(to right, #000046, #1CB5E0);
    background-clip: text;
    color: transparent;
}

.header__buttonContainer{
    padding-top: 6rem;
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 600px){
    .header__text{
        width: 80%;
    }
}

@media screen and (min-width: 900px){
    .header__text{
        width: 60%;
    }

    .header{
        background-image: url('./header__desktop.jpg');
    }
}

@media screen and (min-width: 1400px){
    .header__text{
        width: 50%;
    }

    .header__content{
        width: 75%;
    }
}


@keyframes fade-in{
    0%{
        opacity: 0;
        transform: translateY(-50%);
    }

    75%{
        transform: translateY(0);
    }

    100%{
        opacity: 1;
    }
}
