.footer{
    min-height: 10rem;
    background-color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer__contentWrapper{
    width: 90%;
    margin: 0 auto;
}

.footer__logoWrapper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 4rem;
}

.footer__logo{
    transition: 0.3s all;
}

.footer__logo:hover{
    cursor: pointer;
}

.footer__logo:active{
    opacity: 0.4;
}

.footer__img{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1400px){
    .footer__contentWrapper{
        width: 75%;
    }
}