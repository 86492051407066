.mijnProjecten{
    min-height: 40rem;
    background-color: var(--black);
    padding-bottom: 5rem;
}

.mijnProjecten__titelWrapper{
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.mijnProjecten__titel::after{
    content:"";
    width:10rem;
    height:0.7rem;
    display:block;
    position:absolute;
    background-image: linear-gradient(to right, #000046, #1CB5E0);
}

.mijnProjecten__list{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
    list-style-type: none;
}

.mijnProjecten__listItem{
    width: 100%;
    height: 100%;
    background-color: grey;
    border: 1px solid orange;
    aspect-ratio: 1;
}

.mijnProjecten__listItem:nth-of-type(even){
    background-color: tomato;
}

@media screen and (min-width: 600px) {
    .mijnProjecten__list{
        grid-template-columns: repeat(2, minmax(0,1fr));
    }
}

@media screen and (min-width: 900px) {
    .mijnProjecten__list{
        grid-template-columns: repeat(4, minmax(0,1fr));
    }
}