*, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-size: 62.5%;
    box-sizing: border-box;
}

:root{
    --black: #202020;
    --white: #eee;
}

body{
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    min-height: 100vh;
    color: var(--white);
}

h1{
    font-size: 5rem;
    font-weight: 900;
}

h2{
    font-size: 3rem;
    font-weight: 500;
}

h3{
    font-size: 5rem;
    font-weight: 500;
}

button{
    padding: 1rem 2.5rem;
    border-style: none;
    color: var(--white);
    font-family: 'Roboto', sans-serif;
    transition: 0.3s;
    font-size: 1.8rem;
}

button:hover{
    cursor: pointer;
    background-color: var(--hover);
 
}

.primary{
    background-color: var(--blue);
    outline: 0.3rem solid var(--blue);
}

.primary:hover{
    outline: 0.3rem solid var(--hover);
}

.ghost{
    background-color: transparent;
    /* border: 0.1rem solid var(--blue); */
    outline: 0.3rem solid var(--blue);
}

/* Mobile */
@media screen and (min-width: 600px){
    body{
        font-size: 2rem;
    }
    
    h1{
        font-size: 6rem;
    }
    
    h2{
        font-size: 3.5rem;
    }
    
    h3{
        font-size: 6rem;
    }
    
    button{
        font-size: 2rem;
    }

    .contact__submit{
        font-size: 2rem;
    }

}

/* tablet */
@media screen and (min-width: 900px){
    body{
        font-size: 2rem;
    }
    
    h1{
        font-size: 8rem;
    }
    
    h2{
        font-size: 4rem;
    }
    
    h3{
        font-size: 6.5rem;
    }
    
    button{
        font-size: 2rem;
    }

    .contact__submit{
        font-size: 2rem;
    }
}

/* desktop */
@media screen and (min-width: 1400px){
    
    h1{
        font-size: 10rem;
    }

}